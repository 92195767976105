/* views.css */

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #eaeef0;
}

.container-col {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.container-row {
  display: flex;
  height: 100vh;
}

.app-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
}

.sidebar {
  width: 15%;
  height: 100%;
  background: #333;
  color: #fff;
  top: 0;
  left: 0;
  overflow-x: hidden;
  padding: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

.nav-li {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.nav-li:hover {
  background-color: #555;
}

.collapse-li {
  padding: 10px 20px;
  cursor: pointer;
}

.link {
  display: block;
  width: 100%;
  height: 100%;
  textdecoration: none;
  color: inherit;
}

.active-link {
  display: block;
  width: 100%;
  height: 100%;
  textdecoration: none;
  color: blue;
}

a {
  text-decoration: none;
}

.main-content {
  flex-grow: 1; /* Allow main content to grow and occupy remaining space */
  padding: 20px;
}
