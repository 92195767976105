.error-underline {
  text-decoration-color: red;
  text-decoration-style: wavy;
  text-decoration-line: underline;
  display: inline;
}

.tooltip-marker {
}

.CodeMirror {
  font-family: Consolas, Monaco, "Andale Mono", monospace;
  line-height: 1.5;
}

.CodeMirror-hints {
  font-family: Consolas, Monaco, "Andale Mono", monospace;
}

.CodeMirror-hint {
  font-family: Consolas, Monaco, "Andale Mono", monospace;
}
